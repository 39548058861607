import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home')
  },
  {
    path: '/lawSupervision',
    name: 'lawSupervision',
    component: () => import('../views/lawSupervision')
  },
  {
    path: '/operationalData',
    name: 'operationalData',
    component: () => import('../views/operationalData')
  },
  {
    path: '/periodAnalysis',
    name: 'periodAnalysis',
    component: () => import('../views/periodAnalysis')
  },
  {
    path: '/safetyAlarm',
    name: 'safetyAlarm',
    component: () => import('../views/safetyAlarm')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
