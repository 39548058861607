<template>
    <div class="footer_main">
        <img class="footer_bg" src="../assets/images/蒙版组 24@2x.png" alt="">
        <div class="footer_btns">
            <div class="footer_eve" v-for="(item,index) in btnsData" :key="index" :class="index == store.state.tabIndex?'footer_eve_sel':''" @click="handleTabClick(item,index)">
                <div class="footer_eve_bg">
                    <img :src="store.state.tabIndex==index?item.selImg:item.noImg" alt="">
                </div>
                <span>{{ item.name }}</span>
            </div>
            <!-- <div class="footer_eve">
                <img src="../assets/images/矩形 70@2x.png" alt="">
                <span>运营数据</span>
            </div>
            <div class="footer_eve">
                <img src="../assets/images/矩形 70@2x.png" alt="">
                <span>综合监察</span>
            </div>
            <div class="footer_eve">
                <img src="../assets/images/矩形 70@2x.png" alt="">
                <span>时段分析</span>
            </div>
            <div class="footer_eve">
                <img src="../assets/images/矩形 70@2x.png" alt="">
                <span>执法监管</span>
            </div> -->
        </div>
    </div>
</template>
<script setup name="Footer">
    import {ref,onMounted} from 'vue'
    import {useRouter,useRoute} from 'vue-router'
    import store from '../store';
    const router = useRouter()
    const route = useRoute()

    const btnsData = ref([{
        name:"安全报警",
        url:'/safetyAlarm',
        selImg:require('../assets/images/蒙版组 28@2x.png'),
        noImg:require('../assets/images/蒙版组 20@2x.png'),
    },{
        name:"运营数据",
        url:'/operationalData',
        selImg:require('../assets/images/蒙版组 25@2x.png'),
        noImg:require('../assets/images/蒙版组 21@2x.png'),
    },{
        name:"综合监察",
        url:'/home',
        selImg:require('../assets/images/综合.png'),
        noImg:require('../assets/images/蒙版组 26@2x.png'),
    },{
        name:"时段分析",
        url:'/periodAnalysis',
        selImg:require('../assets/images/时段.png'),
        noImg:require('../assets/images/蒙版组 22@2x.png'),
    },{
        name:"执法监管",
        url:'/lawSupervision',
        selImg:require('../assets/images/蒙版组 19@2x.png'),
        noImg:require('../assets/images/蒙版组 23@2x.png'),
    }])

    const handleTabClick = (item,index)=>{
        // selIndex.value = index
        store.dispatch("selectTabIndex",index)
        router.push(item.url)
    }

</script>
<style lang="less" scoped>
    .footer_main{
        position: relative;
        // background: url('../assets/images/蒙版组 24@2x.png');
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position-y: -10px;
        width: 50%;
        margin: 0 auto;
    }

    .footer_btns{
        display: flex;
        align-items: center;
        justify-content: space-around;
        z-index: 100;
        position: absolute;
        left: 15%;
        // top: -150px;
        bottom: 60px;
        width: 70%;
    }

    .footer_bg{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 99;
        
    }

    .footer_eve_sel{
        span{
            color: white !important;
            font-size: 16px !important;
        }
        .footer_eve_bg{
            width: 70px !important;
            height: 70px !important;
        }
    }

    .footer_eve{
        display: flex;
        flex-direction: column;
        align-items: center;

        img{
            width: 40px;
            height: 40px; 
            object-fit: contain; 
            margin: auto;
        }
        span{
            font-size: 14px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            text-align: center;
            color: #308cff;
        }
    }

    .footer_eve:hover{
        span{
            // color: white !important;
            font-size: 16px !important;
        }
        .footer_eve_bg{
            width: 70px !important;
            height: 70px !important;
        }
    }

    .footer_eve_bg{
        background: url('../assets/images/矩形 70@2x.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 60px;
        height: 60px;
        border: 1px solid rgba(48,140,255,0.20);
        border-radius: 12px;
        margin-bottom: 10px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>