import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BaiduMap from 'vue-baidu-map-3x'

const app = createApp(App);
app.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: '0tTIYkpe9kdKhEISWN3OnYrHG2jTychg',
    // v:'2.0',  // 默认使用3.0
    // type: 'WebGL' // ||API 默认API  (使用此模式 BMap=BMapGL)
  });

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  document.title = `洛阳市充电设施智慧监管系统`;
  // const role = localStorage.getItem('ms_username');
  // if (!role && to.path !== '/login') {
  //     next('/login');
  // } else if (to.meta.permission) {
  //     // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
  //     role === 'admin' ? next() : next('/403');
  // } else {
      // 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
      if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
          Vue.prototype.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
              confirmButtonText: '确定'
          });
      } else {
          next();
      }
  // }
});
app.use(store).use(router).mount('#app')
