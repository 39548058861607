<template>
    <div class="head_nav">
        <div class="head_lt">
            <div class="head_eve">
                <span>温度</span>
                <span>23℃</span>
            </div>
            <div class="head_line"></div>
            <div class="head_eve">
                <span>湿度</span>
                <span>55%</span>
            </div>
            <div class="head_line"></div>
            <div class="head_eve">
                <span>PM2.5</span>
                <div class="head_eve_pm">
                    <span>23</span>
                    <span>ug/m³</span>
                </div>
            </div>
            <div class="head_line"></div>
            <div class="head_eve">
                <span>风力</span>
                <span>5.2</span>
            </div>
        </div>
        <div class="head_ct">
            <!-- <img src="../assets/images/组 53@2x.png" alt=""> -->
            <span>洛阳市充电设施智慧监管系统</span>
        </div>
        <div class="head_rg">
            <div class="head_time">
                {{ nowTime }}
            </div>
            <div class="head_date">
                <div>{{ nowDate }}</div>
                <div>{{ curLunarDate.lunarYear }}({{curLunarDate.zodiac}}) {{ curLunarDate.dateStr }}</div>
            </div>
            <div class="head_btn" @click="togglePureMode" v-if="route.path == '/home'">
                <img src="../assets/images/蒙版组 31@2x.png" alt="">
                <span>纯净模式</span>
                <!-- {{ store.state.isPureMode }} -->
            </div>
            <div class="head_btn" @click="toggleScreen">
                <img src="../assets/images/蒙版组 30@2x.png" alt="">
                <span>退出</span>
            </div>
        </div>
    </div>
</template>
<script setup  name="Header">
    import {getLunar} from 'chinese-lunar-calendar'
    import {ref,onMounted} from 'vue'
    import { api as fullscreen } from 'vue-fullscreen'
    import store from '../store'
    import {useRouter,useRoute} from 'vue-router'
    const route = useRoute()

    let curDate = new Date();
    let year = curDate.getFullYear()
    let month = curDate.getMonth() + 1 
    let day = curDate.getDate()
    let curLunarDate = getLunar(year,month,day)
    
    // console.log(111,curLunarDate)

    const nowTime = ref(null)
    const nowDate = ref(null)

     const isFullscreen = ref(false)
     const teleport = ref(true)

    //  const isPureMode = ref(store.state.isPureMode)

    onMounted(() => {
        currentTime()
    })

    const currentTime = ()=>{
        setInterval(formatDate, 500);
    }

    const formatDate = ()=>{
        let date = new Date();
        let year = date.getFullYear(); // 手
        let month = date.getMonth() + 1; // 月
        month = month< 10 ? "0" + month : month; // 如只有一位，则就面补务let day = date.getDate(); // 9
        day = day < 1 ? "0" + day : day; // 如只有一位，则前面郑季
        let week = date.getDay(); // 星期
        let weekArr = ["星期日","星期一","星期二","星期三","星期四","星期五", "星期六"]
        let hour = date.getHours(); // 
        hour = hour < 10 ? "0" + hour : hour; // 如只有一位，则面孙等
        let minute = date.getMinutes(); // 
        minute = minute< 10 ?"0"+ minute : minute; // 如只有一位，则面郑等
        let second = date.getSeconds(); // 
        second = second< 10 ?"0"+ second : second; // 如只有一位，则前面补
        nowDate.value =`${year}/${month}/${day} ${weekArr[week]}`;
        nowTime.value =`${hour} : ${minute}`;
        // console.log(111,nowTime,nowDate)
    }

     const toggleScreen = async()=>{
      await fullscreen.toggle(document.querySelector('.fullscreen-wrapper'), {
        teleport: teleport.value,
        callback: (isFullscreen) => {
          //this.fullscreen = isFullscreen
        },
      })
      isFullscreen.value = fullscreen.isFullscreen
    }
    const togglePureMode = async ()=>{
        await store.dispatch("togglePureMode")
    }
        
    

</script>
<style lang="less" scoped>

    .head_nav{
        position: relative;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 23px 0;
        background-image: url("../assets/images/组\ 53@2x.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .head_lt{
        display: flex;
        align-items: center;
    }
    .head_rg{
        display: flex;
        align-items: center;
    }

    .head_ct{
        font-size: 46px;
        font-family: PangMenZhengDao, PangMenZhengDao-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16); 

        // img{
        //     width: 100%;
        //     object-fit: contain;
        //     position: absolute;
        //     left: ;
        // }
    }

    .head_eve{
        display: flex;
        flex-direction: column;
        align-items: center;

        span:first-child{
            font-size: 14px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #ffffff;
        }
        span:nth-child(2){
            font-size: 28px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #ffffff;
        }
        span:nth-child(3){
            font-size: 14px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #ffffff;
        }
    }

    .head_eve_pm span:first-child{
        font-size: 28px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
    }
    .head_eve_pm span:last-child{
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
    }

    .head_line{
        // background: linear-gradient(90deg,#000000 0%, rgba(0,0,0,0.00) 99%);
        height: 30px;
        width: 1px;
        background: #707070;
        margin: 0px 30px;
    }
    .head_time{
        font-size: 42px;
        font-family: UniDreamLED, UniDreamLED-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        margin-right: 30px;
    }
    .head_date{
        font-size: 14px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
    }
    .head_btn{

        display: flex;
        flex-direction: column;
        align-items: center;

        font-size: 12px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;

        margin-left: 30px;

        img{
            width: 32px;
            height: 32px;
        }
    }
    .head_btn:hover{
        color: #ffffff;
    }
</style>