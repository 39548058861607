import { createStore } from 'vuex'

export default createStore({
  state: {
    isPureMode:false,
    tabIndex:2,
    lawSelShow:false,
    lawSelItem:null,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    togglePureMode(){
      this.state.isPureMode = !this.state.isPureMode
    },
    selectTabIndex({commit},index){
      this.state.tabIndex = index
    },
    selectLawItem({commit},item){
      this.state.lawSelItem = item
    }
  },
  modules: {
  }
})
